import React, {Component} from "react";
import Slider from "react-slick";
import {Transition} from "semantic-ui-react"
import Typewriter from 'typewriter-effect';

export default class Slideshow extends Component {
  state = {visible: true}

  render() {
    const {visible} = this.state
    const settings = {
      dots: true,
      infinite: true,
      speed: 5,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const data = [{
      url: "https://blog-uploads.associatedpress.com/blog/news/_951x591_fit_center-center_75_none/KashmirEndlessWar__FinalEdit_Pulitzer_001.jpg",
      source: "ap",
      title: "",
      info: "A masked Kashmiri protester jumps on the bonnet of an armored vehicle of Indian police as he throws stones at it during a protest in Srinagar, Indian controlled Kashmir, May 31, 2019.",

    },
      {
        url: "https://blog-uploads.associatedpress.com/blog/news/_951x591_fit_center-center_75_none/KashmirEndlessWar__FinalEdit_Pulitzer_001.jpg"
      },
      {
        url: "https://blog.ap.org/uploads/news/_951x591_fit_center-center_75/KashmirEndlessWar__FinalEdit_Pulitzer_006.jpg"
      },

      {
        url: "https://ep00.epimg.net/elpais/imagenes/2020/02/27/album/1582793314_374267_1582824538_album_normal.jpg",
        source: "ap",
        title: "elPais",
        dateposted: "27/02/2020",
        worldLink: "https://elpais.com/elpais/2020/02/27/album/1582793314_374267.html#foto_gal_1",
        info: "Protesters protest against the construction of new migrant camps in Mytilene, on the island of Lesbos (Greece).",

      },
    ]


    return (
      <React.Fragment>
        <Slider {...settings}>
          {
            data.map(img => {
              return <div className={"slide"} key={img.source}>

                <div className={`${img.source}_logo`}>

                </div>

                <Typewriter
                  onInit={(typewriter) => {
                    typewriter.typeString(img.info)
                      .callFunction(() => {
                        console.log('String typed out!');
                      })
                      .pauseFor(1000)
                      .deleteAll()
                      .callFunction(() => {
                        console.log('All strings were deleted');
                      })
                      .start();
                  }}/>
                <img src={img.url}/>
                <p>
                  {img.source}
                </p>
                <p></p>
              </div>
            })
          }

        </Slider>
      </React.Fragment>
    );
  }
}
