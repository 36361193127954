import React from "react";
import "../my.scss";
import {FaHeart} from "react-icons/fa";
import {Breadcrumb} from "semantic-ui-react"
import { Link} from "react-router-dom";
import logo from "../images/logo/ajit.png"


export default class AjitTemplate extends React.Component {
    render() {
        return <div className={"component"} style={{minHeight: "100vh"}}>

            <div className={"ajit"}>
                <Link to={"/nerdstats"}>
                <FaHeart/>90%
                </Link>
                <div className={"branding"}>
                    <img src={logo} alt={""}/>
                </div>


            </div>
            <div className={"contentContainer"}>
              <Breadcrumb>
                <Breadcrumb.Section link>O</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section link>Ajit</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section link>Month</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>Day</Breadcrumb.Section>
              </Breadcrumb>
                <h2>
                    ਡੇਢ ਸਾਲ ਤੱਕ ਨਹੀਂ ਵਧੇਗਾ ਕੇਂਦਰੀ ਮੁਲਾਜ਼ਮਾਂ ਦਾ ਮਹਿੰਗਾਈ ਭੱਤਾ
                </h2>
                <img src={"http://beta.ajitjalandhar.com/cmsimages/20200424/3040202__d217455830.jpg"} width={"300px"} alt={""}/>
                <div>
                    <span className={"author"}>
                    ਨਵੀਂ ਦਿੱਲੀ, 23 ਅਪ੍ਰੈਲ (ਉਪਮਾ ਡਾਗਾ ਪਾਰਥ)
                        </span>
                        ਕੋਰੋਨਾ ਵਾਇਰਸ ਦੇ ਆਰਥਿਕਤਾ &apos;ਤੇ ਪੈਣ ਵਾਲੇ ਨਾਂਹ-ਪੱਖੀ ਪ੍ਰਭਾਵਾਂ ਤਹਿਤ ਸਰਕਾਰ ਨੇ ਕੇਂਦਰੀ ਮੁਲਾਜ਼ਮਾਂ ਅਤੇ ਪੈਨਸ਼ਨਰਾਂ ਨੂੰ ਵੱਡਾ ਝਟਕਾ ਦਿੰਦਿਆਂ ਉਨ੍ਹਾਂ ਨੂੰ ਦਿੱਤੇ ਜਾਣ ਵਾਲੇ ਵਾਧੂ ਮਹਿੰਗਾਈ ਭੱਤੇ &apos;ਤੇ ਰੋਕ ਲਾ ਦਿੱਤੀ ਹੈ। ਕੇਂਦਰ ਸਰਕਾਰ ਨੇ ਡੇਢ ਨਾਲ ਭਾਵ 1 ਜਨਵਰੀ, 2020 ਤੋਂ 1 ਜੁਲਾਈ, 2021 ਦਰਮਿਆਨ ਦੇ ਸਮੇਂ ਲਈ ਮਹਿੰਗਾਈ ਭੱਤੇ ਦੀ ਦਰ &apos;ਚ ਕੋਈ ਤਬਦੀਲੀ ਨਾ ਕਰਨ ਦਾ ਫ਼ੈਸਲਾ ਕੀਤਾ ਹੈ। ਹਾਲਾਂਕਿ ਵਿੱਤ ਮੰਤਰਾਲੇ ਨੇ ਇਹ ਸਪੱਸ਼ਟ ਕੀਤਾ ਹੈ ਕਿ ਮਹਿੰਗਾਈ ਭੱਤੇ ਦਾ ਭੁਗਤਾਨ ਮੌਜੂਦਾ 17 ਫ਼ੀਸਦੀ ਦੀ ਦਰ ਨਾਲ ਕੀਤਾ ਜਾਂਦਾ ਰਹੇਗਾ। ਵਿੱਤ ਮੰਤਰਾਲੇ ਵਲੋਂ ਦਿੱਤੀ ਜਾਣਕਾਰੀ ਮੁਤਾਬਿਕ ਕੇਂਦਰ ਸਰਕਾਰ ਦੇ ਮੁਲਾਜ਼ਮਾਂ ਅਤੇ ਪੈਨਸ਼ਨ ਧਾਰਕਾਂ ਨੂੰ 1 ਜਨਵਰੀ, 2020 ਤੋਂ ਦਿੱਤੇ ਜਾਣ ਵਾਲੇ ਮਹਿੰਗਾਈ ਭੱਤੇ ਦੀ ਵਾਧੂ ਕਿਸ਼ਤ ਨਹੀਂ ਦਿੱਤੀ ਗਈ। ਜ਼ਿਕਰਯੋਗ ਹੈ ਕਿ ਕੇਂਦਰ ਸਰਕਾਰ ਵਲੋਂ ਹਰ 6 ਮਹੀਨੇ ਦੇ ਵਕਫ਼ੇ ਦਰਮਿਆਨ ਮਹਿੰਗਾਈ ਭੱਤੇ ਦੀ ਦਰ &apos;ਚ ਤਬਦੀਲੀ ਹੁੰਦੀ ਹੈ ਅਤੇ ਤਬਦੀਲੀ ਤੋਂ ਬਾਅਦ ਮਹਿੰਗਾਈ ਭੱਤੇ ਦਾ ਭੁਗਤਾਨ ਹਰ ਸਾਲ 1
                    ਜਨਵਰੀ ਅਤੇ 1 ਜੁਲਾਈ ਨੂੰ ਕੀਤਾ ਜਾਂਦਾ ਹੈ। ਮੰਤਰਾਲੇ ਮੁਤਾਬਿਕ ਕੇਂਦਰੀ ਮੁਲਾਜ਼ਮਾਂ ਅਤੇ ਪੈਨਸ਼ਨ ਧਾਰਕਾਂ ਨੂੰ 1 ਜੁਲਾਈ, 2020 ਅਤੇ 1 ਜਨਵਰੀ, 2021 ਤੋਂ ਬਕਾਇਆ ਹੋਣ ਵਾਲੇ ਮਹਿੰਗਾਈ ਭੱਤੇ ਦੀ ਵਾਧੂ ਕਿਸ਼ਤ ਦਾ ਭੁਗਤਾਨ ਨਹੀਂ ਕੀਤਾ ਜਾਵੇਗਾ। ਵਿੱਤ ਮੰਤਰਾਲੇ ਨੇ ਇਹ ਵੀ ਕਿਹਾ ਕਿ 1 ਜਨਵਰੀ, 2020 ਤੋਂ 30 ਜੂਨ 2021 ਦੀ ਮਿਆਦ ਦਾ ਕੋਈ ਵੀ ਬਕਾਇਆ ਅਦਾ ਨਹੀਂ ਕੀਤਾ ਜਾਵੇਗਾ। ਕੇਂਦਰ ਸਰਕਾਰ ਦੇ ਇਸ ਫ਼ੈਸਲੇ ਕਾਰਨ 1.13 ਕਰੋੜ ਮੁਲਾਜ਼ਮ ਅਤੇ ਪੈਨਸ਼ਨਰ ਪ੍ਰਭਾਵਿਤ ਹੋਣਗੇ। ਇਸ ਮਹਿੰਗਾਈ ਭੱਤੇ ਕਾਰਨ ਕੇਂਦਰ ਸਰਕਾਰ ਨੂੰ 2020-21 ਅਤੇ 2021-22 ਦਰਮਿਆਨ ਤਕਰੀਬਨ 37,530 ਕਰੋੜ ਰੁਪਏ ਦੀ ਬੱਚਤ ਹੋਵੇਗੀ। ਕੇਂਦਰ ਸਰਕਾਰ ਦੇ ਫ਼ੈਸਲੇ ਤੋਂ ਬਾਅਦ ਰਾਜ ਸਰਕਾਰ ਵਲੋਂ ਵੀ ਮਹਿੰਗਾਈ ਭੱਤੇ ਰੋਕਣ ਦੀ ਸੰਭਾਵਨਾ ਹੈ। ਜੇਕਰ ਰਾਜ ਸਰਕਾਰਾਂ ਵੀ ਅਜਿਹਾ ਕਦਮ ਚੁੱਕਦੀਆਂ ਹਨ ਤਾਂ ਸਰਕਾਰ ਤਕਰੀਬਨ 82.566 ਕਰੋੜ ਰੁਪਏ ਬਚਾ ਸਕਦੀ ਹੈ।
                </div>


                #8b0100

                http://epaper.ajitsamachar.com/images/logo160.png

            </div>

        </div>
    }
}
