import React from 'react';
import "../my.scss";
import bull from "../th_adobe_express.svg";
import bear from "../bear.svg";
import Slide from 'react-reveal/Reveal';
import 'c3/c3.css';
import * as d3 from "d3";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import PropTypes from 'prop-types';




class BarChart extends React.Component {
    componentDidMount() {
        this.drawChart();
    }
    drawChart() {
        const data = [12, 5, 6, 6, 9, 10];

        const svg = d3.select(".contentContainer")
            .append("svg")
            .attr("width", 700)
            .attr("height", 300);

        svg.selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (d, i) => i * 70)
            .attr("y", (d) => 300 - 10 * d)
            .attr("width", 65)
            .attr("height", (d) => d * 10)
            .attr("fill", "green");
    }
    render() {
        return <div id={`#${this.props.id}`}></div>
    }
}

BarChart.propTypes = {
    id: PropTypes.string
}
// var formatDate = d3.timeFormat("%d-%b-%y");
const data = {
    json: [
        {
            date: new Date("2022-07-25"), Open: 25.5, High: 25.75, Low: 25, Close: 25.5, AdjClose: 25.5, Volume: 826844
        }, {
            date: new Date("2022-07-26"), Open: 25, High: 25.25, Low: 20.5, Close: 21.75, AdjClose: 21.75, Volume: 4139904
        }, {
            date: new Date("2022-07-27"), Open: 22.25, High: 22.75, Low: 20.5, Close: 21.75, AdjClose: 21.75, Volume: 2170904
        }, {
            date: new Date("2022-07-28"), Open: 21, High: 21, Low: 20, Close: 20.25, AdjClose: 20.25, Volume: 1793840
        }, {
            date: new Date("2022-07-29"), Open: 20.25, High: 20.25, Low: 19, Close: 19.75, AdjClose: 19.75, Volume: 1632328
        }, {
            date: new Date("2022-08-01"), Open: 19.5, High: 23, Low: 19, Close: 21.75, AdjClose: 21.75, Volume: 3075224
        }, {
            date: new Date("2022-08-02"), Open: 21.25, High: 23.75, Low: 20.75, Close: 23, AdjClose: 23, Volume: 2148292
        }, {
            date: new Date("2022-08-03"), Open: 23.25, High: 24, Low: 22.75, Close: 23, AdjClose: 23, Volume: 1680384
        }, {
            date: new Date("2022-08-04"), Open: 22.5, High: 23.25, Low: 20.25, Close: 21, AdjClose: 21, Volume: 3151256
        }, {
            date: new Date("2022-08-05"), Open: 21, High: 22.25, Low: 20.75, Close: 21.25, AdjClose: 21.25, Volume: 1261336
        }, {
            date: new Date("2022-08-08"), Open: 20.25, High: 21.25, Low: 19.5, Close: 20, AdjClose: 20, Volume: 2976672
        }, {
            date: new Date("2022-08-09"), Open: 20.5, High: 20.5, Low: 19.5, Close: 19.75, AdjClose: 19.75, Volume: 1507280
        }, {
            date: new Date("2022-08-10"), Open: 20, High: 20.5, Low: 19.5, Close: 20.25, AdjClose: 20.25, Volume: 2627772
        }, {
            date: new Date("2022-08-11"), Open: 20.5, High: 20.75, Low: 19.75, Close: 20.5, AdjClose: 20.5, Volume: 2095608
        }, {
            date: new Date("2022-08-12"), Open: 20.25, High: 21.5, Low: 19.5, Close: 20.75, AdjClose: 20.75, Volume: 3604700
        }, {
            date: new Date("2022-08-15"), Open: 21.25, High: 23.25, Low: 21.25, Close: 22.75, AdjClose: 22.75, Volume: 4531324
        }, {
            date: new Date("2022-08-16"), Open: 22.75, High: 22.75, Low: 20.5, Close: 22.25, AdjClose: 22.25, Volume: 3951724
        }, {
            date: new Date("2022-08-17"), Open: 21.5, High: 21.75, Low: 20.75, Close: 21, AdjClose: 21, Volume: 3192908
        }, {
            date: new Date("2022-08-18"), Open: 21, High: 21, Low: 20, Close: 20.25, AdjClose: 20.25, Volume: 1775272
        }, {
            date: new Date("2022-08-19"), Open: 20, High: 20.25, Low: 18.75, Close: 18.75, AdjClose: 18.75, Volume: 2810536
        }, {
            date: new Date("2022-08-22"), Open: 19.75, High: 20.25, Low: 17.5, Close: 17.75, AdjClose: 17.75, Volume: 3955368
        }, {
            date: new Date("2022-08-23"), Open: 17.75, High: 18.5, Low: 16.75, Close: 17, AdjClose: 17, Volume: 1941476
        }, {
            date: new Date("2022-08-24"), Open: 17.5, High: 18, Low: 17.25, Close: 17.5, AdjClose: 17.5, Volume: 2739328
        }, {
            date: new Date("2022-08-25"), Open: 18, High: 18, Low: 15.75, Close: 16.75, AdjClose: 16.75, Volume: 3533424
        }, {
            date: new Date("2022-08-26"), Open: 17.25, High: 17.25, Low: 15.75, Close: 16, AdjClose: 16, Volume: 3763216
        }, {
            date: new Date("2022-08-29"), Open: 15.75, High: 16.5, Low: 15.25, Close: 15.5, AdjClose: 15.5, Volume: 2618552
        }, {
            date: new Date("2022-08-30"), Open: 16, High: 16, Low: 15, Close: 16, AdjClose: 16, Volume: 3131484
        }, {
            date: new Date("2022-08-31"), Open: 16, High: 18.5, Low: 15.75, Close: 16.75, AdjClose: 16.75, Volume: 5055620
        }, {
            date: new Date("2022-09-01"), Open: 17.25, High: 17.25, Low: 15.25, Close: 16, AdjClose: 16, Volume: 3660212
        }, {
            date: new Date("2022-09-02"), Open: 16.25, High: 16.25, Low: 15.25, Close: 15.75, AdjClose: 15.75, Volume: 2614796
        }, {
            date: new Date("2022-09-06"), Open: 16, High: 17.5, Low: 15, Close: 16.5, AdjClose: 16.5, Volume: 4064316
        }, {
            date: new Date("2022-09-07"), Open: 16, High: 17.25, Low: 16, Close: 16.75, AdjClose: 16.75, Volume: 2180336
        }, {
            date: new Date("2022-09-08"), Open: 18.25, High: 19.75, Low: 15, Close: 15.25, AdjClose: 15.25, Volume: 7899684
        }, {
            date: new Date("2022-09-09"), Open: 15.75, High: 16, Low: 13.25, Close: 14, AdjClose: 14, Volume: 13921744
        }, {
            date: new Date("2022-09-12"), Open: 14.25, High: 14.75, Low: 13.5, Close: 14.5, AdjClose: 14.5, Volume: 5552512
        }, {
            date: new Date("2022-09-13"), Open: 13.75, High: 14, Low: 13.25, Close: 13.5, AdjClose: 13.5, Volume: 3202212
        }, {
            date: new Date("2022-09-14"), Open: 13.25, High: 13.75, Low: 13, Close: 13.25, AdjClose: 13.25, Volume: 2419940
        }, {
            date: new Date("2022-09-15"), Open: 13.25, High: 14.25, Low: 13.25, Close: 13.5, AdjClose: 13.5, Volume: 3757852
        }, {
            date: new Date("2022-09-16"), Open: 13.25, High: 13.25, Low: 12.5, Close: 12.5, AdjClose: 12.5, Volume: 4965076
        }, {
            date: new Date("2022-09-19"), Open: 12.5, High: 12.5, Low: 11.5, Close: 11.5, AdjClose: 11.5, Volume: 3618908
        }, {
            date: new Date("2022-09-20"), Open: 11.75, High: 12, Low: 10.75, Close: 11, AdjClose: 11, Volume: 2824308
        }, {
            date: new Date("2022-09-21"), Open: 10.75, High: 11, Low: 10, Close: 10.25, AdjClose: 10.25, Volume: 3633928
        }, {
            date: new Date("2022-09-22"), Open: 10.25, High: 10.25, Low: 9, Close: 9.25, AdjClose: 9.25, Volume: 3415036
        }, {
            date: new Date("2022-09-23"), Open: 8.5, High: 11, Low: 8.25, Close: 10, AdjClose: 10, Volume: 6452056
        }, {
            date: new Date("2022-09-26"), Open: 10.25, High: 10.25, Low: 8.5, Close: 8.5, AdjClose: 8.5, Volume: 4323400
        }, {
            date: new Date("2022-09-27"), Open: 8.75, High: 9, Low: 8, Close: 8.25, AdjClose: 8.25, Volume: 3670608
        }, {
            date: new Date("2022-09-28"), Open: 8.25, High: 9.25, Low: 8, Close: 9, AdjClose: 9, Volume: 2902396
        }, {
            date: new Date("2022-09-29"), Open: 8.75, High: 8.75, Low: 8.25, Close: 8.5, AdjClose: 8.5, Volume: 1946984
        }, {
            date: new Date("2022-09-30"), Open: 8.5, High: 8.5, Low: 8, Close: 8.25, AdjClose: 8.25, Volume: 2834184
        }, {
            date: new Date("2022-10-03"), Open: 8.25, High: 8.25, Low: 7.75, Close: 8, AdjClose: 8, Volume: 2310328
        }, {
            date: new Date("2022-10-04"), Open: 8.5, High: 9, Low: 8.25, Close: 9, AdjClose: 9, Volume: 3113676
        }, {
            date: new Date("2022-10-05"), Open: 8.75, High: 8.75, Low: 8, Close: 8.5, AdjClose: 8.5, Volume: 2143724
        }, {
            date: new Date("2022-10-06"), Open: 8.5, High: 8.75, Low: 8.25, Close: 8.25, AdjClose: 8.25, Volume: 1602124
        }, {
            date: new Date("2022-10-07"), Open: 8.25, High: 8.25, Low: 7.5, Close: 7.5, AdjClose: 7.5, Volume: 2196336
        }, {
            date: new Date("2022-10-10"), Open: 7.5, High: 7.5, Low: 6, Close: 6.5, AdjClose: 6.5, Volume: 5267616
        }, {
            date: new Date("2022-10-11"), Open: 6.75, High: 6.75, Low: 6.25, Close: 6.25, AdjClose: 6.25, Volume: 2687772
        }, {
            date: new Date("2022-10-12"), Open: 6.25, High: 6.5, Low: 6, Close: 6.25, AdjClose: 6.25, Volume: 2135052
        }, {
            date: new Date("2022-10-13"), Open: 6, High: 6.25, Low: 5.75, Close: 6, AdjClose: 6, Volume: 2345884
        }, {
            date: new Date("2022-10-14"), Open: 6.25, High: 6.75, Low: 5.75, Close: 5.75, AdjClose: 5.75, Volume: 3101088
        }, {
            date: new Date("2022-10-17"), Open: 6, High: 6.25, Low: 5.75, Close: 5.75, AdjClose: 5.75, Volume: 2239800
        }, {
            date: new Date("2022-10-18"), Open: 6, High: 6.25, Low: 5.25, Close: 5.5, AdjClose: 5.5, Volume: 4148660
        }, {
            date: new Date("2022-10-19"), Open: 5.5, High: 10.25, Low: 5.5, Close: 8.5, AdjClose: 8.5, Volume: 26352352
        }, {
            date: new Date("2022-10-20"), Open: 10.25, High: 11.5, Low: 8.5, Close: 8.75, AdjClose: 8.75, Volume: 14298104
        }, {
            date: new Date("2022-10-21"), Open: 8.75, High: 9.5, Low: 8, Close: 9.5, AdjClose: 9.5, Volume: 7856760
        }, {
            date: new Date("2022-10-24"), Open: 11.75, High: 13.5, Low: 11.25, Close: 12.5, AdjClose: 12.5, Volume: 20371164
        }, {
            date: new Date("2022-10-25"), Open: 15, High: 15.25, Low: 11.5, Close: 12.75, AdjClose: 12.75, Volume: 18466960
        }, {
            date: new Date("2022-10-26"), Open: 12.75, High: 14, Low: 11.75, Close: 13.5, AdjClose: 13.5, Volume: 11174052
        }, {
            date: new Date("2022-10-27"), Open: 14, High: 14.75, Low: 13, Close: 13.5, AdjClose: 13.5, Volume: 10955504
        }, {
            date: new Date("2022-10-28"), Open: 12.25, High: 13.25, Low: 11.75, Close: 13, AdjClose: 13, Volume: 7589232
        }, {
            date: new Date("2022-10-31"), Open: 12.75, High: 13.5, Low: 11.75, Close: 12, AdjClose: 12, Volume: 6226868
        }, {
            date: new Date("2022-11-01"), Open: 11, High: 11.5, Low: 9.75, Close: 10, AdjClose: 10, Volume: 7590968
        }, {
            date: new Date("2022-11-02"), Open: 9.75, High: 10.25, Low: 8.75, Close: 9, AdjClose: 9, Volume: 10154796
        }, {
            date: new Date("2022-11-03"), Open: 8.5, High: 9, Low: 7.5, Close: 7.5, AdjClose: 7.5, Volume: 9274860
        }, {
            date: new Date("2022-11-04"), Open: 7.5, High: 7.5, Low: 6.75, Close: 7, AdjClose: 7, Volume: 10728972
        }, {
            date: new Date("2022-11-07"), Open: 7, High: 7, Low: 6.25, Close: 6.75, AdjClose: 6.75, Volume: 6829868
        }, {
            date: new Date("2022-11-08"), Open: 6.5, High: 7.5, Low: 6.5, Close: 7, AdjClose: 7, Volume: 9234640
        }, {
            date: new Date("2022-11-09"), Open: 8, High: 8, Low: 6.75, Close: 6.75, AdjClose: 6.75, Volume: 9677784
        }, {
            date: new Date("2022-11-10"), Open: 7.25, High: 8.25, Low: 6.75, Close: 8.25, AdjClose: 8.25, Volume: 9035112
        }, {
            date: new Date("2022-11-11"), Open: 7.75, High: 8.25, Low: 7.25, Close: 7.75, AdjClose: 7.75, Volume: 7716300
        }, {
            date: new Date("2022-11-14"), Open: 7.75, High: 8, Low: 7.25, Close: 7.5, AdjClose: 7.5, Volume: 6136336
        }, {
            date: new Date("2022-11-15"), Open: 7.75, High: 7.75, Low: 7.25, Close: 7.25, AdjClose: 7.25, Volume: 6748176
        }, {
            date: new Date("2022-11-16"), Open: 7, High: 7.25, Low: 6.5, Close: 6.75, AdjClose: 6.75, Volume: 6549900
        }, {
            date: new Date("2022-11-17"), Open: 7.25, High: 8.25, Low: 6.75, Close: 6.75, AdjClose: 6.75, Volume: 9437656
        }, {
            date: new Date("2022-11-18"), Open: 7, High: 7, Low: 6.75, Close: 6.75, AdjClose: 6.75, Volume: 5124872
        }, {
            date: new Date("2022-11-21"), Open: 6.75, High: 6.75, Low: 6.25, Close: 6.5, AdjClose: 6.5, Volume: 6395268
        }, {
            date: new Date("2022-11-22"), Open: 6, High: 6.25, Low: 5.75, Close: 5.75, AdjClose: 5.75, Volume: 6382364
        }, {
            date: new Date("2022-11-23"), Open: 5.75, High: 6, Low: 5, Close: 5, AdjClose: 5, Volume: 11122212
        }, {
            date: new Date("2022-11-25"), Open: 5.25, High: 5.25, Low: 4.75, Close: 5, AdjClose: 5, Volume: 6731316
        }, {
            date: new Date("2022-11-28"), Open: 5, High: 5, Low: 4.75, Close: 4.75, AdjClose: 4.75, Volume: 4944404
        }, {
            date: new Date("2022-11-29"), Open: 5, High: 5.25, Low: 4.75, Close: 5, AdjClose: 5, Volume: 7552268
        }, {
            date: new Date("2022-11-30"), Open: 5, High: 5, Low: 4.5, Close: 4.75, AdjClose: 4.75, Volume: 7652396
        }, {
            date: new Date("2022-12-01"), Open: 5.5, High: 6, Low: 5.25, Close: 5.5, AdjClose: 5.5, Volume: 15811232
        }, {
            date: new Date("2022-12-02"), Open: 5, High: 5.25, Low: 5, Close: 5, AdjClose: 5, Volume: 7069500
        }, {
            date: new Date("2022-12-05"), Open: 5.25, High: 5.25, Low: 4.75, Close: 5, AdjClose: 5, Volume: 4658700
        }, {
            date: new Date("2022-12-06"), Open: 5, High: 5, Low: 4.75, Close: 4.75, AdjClose: 4.75, Volume: 4436104
        }, {
            date: new Date("2022-12-07"), Open: 4.5, High: 5, Low: 4.5, Close: 4.75, AdjClose: 4.75, Volume: 4618268
        }, {
            date: new Date("2022-12-08"), Open: 5, High: 5.25, Low: 4.75, Close: 4.75, AdjClose: 4.75, Volume: 5545192
        }, {
            date: new Date("2022-12-09"), Open: 5, High: 5, Low: 4.75, Close: 5, AdjClose: 5, Volume: 4110984
        }, {
            date: new Date("2022-12-12"), Open: 5, High: 5, Low: 4.75, Close: 4.75, AdjClose: 4.75, Volume: 3456160
        }, {
            date: new Date("2022-12-13"), Open: 5.25, High: 5.75, Low: 5, Close: 5.25, AdjClose: 5.25, Volume: 9906168
        }, {
            date: new Date("2022-12-14"), Open: 5.75, High: 6.75, Low: 5.75, Close: 6.5, AdjClose: 6.5, Volume: 16277484
        }, {
            date: new Date("2022-12-15"), Open: 8, High: 10, Low: 6.75, Close: 7.75, AdjClose: 7.75, Volume: 25203916
        }, {
            date: new Date("2022-12-16"), Open: 8.25, High: 8.5, Low: 7, Close: 7.5, AdjClose: 7.5, Volume: 13877028
        }, {
            date: new Date("2022-12-19"), Open: 7.25, High: 7.25, Low: 6.5, Close: 6.75, AdjClose: 6.75, Volume: 6547164
        }, {
            date: new Date("2022-12-20"), Open: 6.5, High: 6.5, Low: 5.5, Close: 5.75, AdjClose: 5.75, Volume: 9140376
        }, {
            date: new Date("2022-12-21"), Open: 5.5, High: 6, Low: 5.25, Close: 5.5, AdjClose: 5.5, Volume: 5953988
        }, {
            date: new Date("2022-12-22"), Open: 6.25, High: 6.5, Low: 5.75, Close: 5.75, AdjClose: 5.75, Volume: 6792124
        }, {
            date: new Date("2022-12-23"), Open: 6, High: 6, Low: 5.5, Close: 5.75, AdjClose: 5.75, Volume: 5016236
        }, {
            date: new Date("2022-12-27"), Open: 5.5, High: 5.75, Low: 5.25, Close: 5.5, AdjClose: 5.5, Volume: 4310812
        }, {
            date: new Date("2022-12-28"), Open: 5.25, High: 5.5, Low: 5, Close: 5.25, AdjClose: 5.25, Volume: 4755636
        }, {
            date: new Date("2022-12-29"), Open: 5.75, High: 6.75, Low: 5.5, Close: 6.5, AdjClose: 6.5, Volume: 8895940
        }, {
            date: new Date("2022-12-30"), Open: 6.5, High: 7.75, Low: 6.5, Close: 7.25, AdjClose: 7.25, Volume: 8325968
        }, {
            date: new Date("2023-01-03"), Open: 8, High: 8.25, Low: 7.25, Close: 8, AdjClose: 8, Volume: 8868112
        }, {
            date: new Date("2023-01-04"), Open: 7.75, High: 8.5, Low: 7, Close: 8.5, AdjClose: 8.5, Volume: 8378784
        }, {
            date: new Date("2023-01-05"), Open: 8.75, High: 11.25, Low: 8.5, Close: 10.5, AdjClose: 10.5, Volume: 21657496
        }, {
            date: new Date("2023-01-06"), Open: 10.25, High: 12, Low: 9.25, Close: 9.5, AdjClose: 9.5, Volume: 23639784
        }, {
            date: new Date("2023-01-09"), Open: 8.75, High: 10, Low: 8.25, Close: 9.25, AdjClose: 9.25, Volume: 9323240
        }, {
            date: new Date("2023-01-10"), Open: 9.25, High: 9.75, Low: 9, Close: 9.5, AdjClose: 9.5, Volume: 6393864
        }, {
            date: new Date("2023-01-11"), Open: 9.75, High: 10.25, Low: 9.5, Close: 10, AdjClose: 10, Volume: 7999676
        }, {
            date: new Date("2023-01-12"), Open: 10, High: 10.25, Low: 9, Close: 9.75, AdjClose: 9.75, Volume: 9121536
        }, {
            date: new Date("2023-01-13"), Open: 9.5, High: 9.75, Low: 8.75, Close: 9, AdjClose: 9, Volume: 8498560
        }, {
            date: new Date("2023-01-17"), Open: 7.75, High: 8.75, Low: 7.5, Close: 7.5, AdjClose: 7.5, Volume: 9936116
        }, {
            date: new Date("2023-01-18"), Open: 7.75, High: 8, Low: 7, Close: 7, AdjClose: 7, Volume: 7777708
        }, {
            date: new Date("2023-01-19"), Open: 6.75, High: 7, Low: 6.25, Close: 6.75, AdjClose: 6.75, Volume: 7490876
        }, {
            date: new Date("2023-01-20"), Open: 7, High: 8, Low: 6.5, Close: 7.5, AdjClose: 7.5, Volume: 11245552
        }, {
            date: new Date("2023-01-23"), Open: 7.5, High: 8, Low: 7.5, Close: 7.5, AdjClose: 7.5, Volume: 7559276
        }, {
            date: new Date("2023-01-24"), Open: 7.5, High: 7.75, Low: 7.25, Close: 7.25, AdjClose: 7.25, Volume: 7122124
        }, {
            date: new Date("2023-01-25"), Open: 7.25, High: 9, Low: 7, Close: 8.75, AdjClose: 8.75, Volume: 16211528
        }, {
            date: new Date("2023-01-26"), Open: 8.75, High: 9, Low: 8, Close: 8, AdjClose: 8, Volume: 9235104
        }, {
            date: new Date("2023-01-27"), Open: 8, High: 8.75, Low: 8, Close: 8.25, AdjClose: 8.25, Volume: 9888060
        }, {
            date: new Date("2023-01-30"), Open: 8.25, High: 8.5, Low: 8, Close: 8.25, AdjClose: 8.25, Volume: 4741008
        }, {
            date: new Date("2023-01-31"), Open: 8.25, High: 9, Low: 8.25, Close: 8.5, AdjClose: 8.5, Volume: 8722352
        }, {
            date: new Date("2023-02-01"), Open: 8.5, High: 9, Low: 8.25, Close: 8.75, AdjClose: 8.75, Volume: 6038536
        }, {
            date: new Date("2023-02-02"), Open: 9.5, High: 10.25, Low: 9, Close: 9.5, AdjClose: 9.5, Volume: 11626380
        }, {
            date: new Date("2023-02-03"), Open: 10.25, High: 11.25, Low: 9.75, Close: 10.25, AdjClose: 10.25, Volume: 11268192
        }, {
            date: new Date("2023-02-06"), Open: 10.75, High: 11.25, Low: 10.25, Close: 10.5, AdjClose: 10.5, Volume: 6182892
        }, {
            date: new Date("2023-02-07"), Open: 10.5, High: 11, Low: 9.25, Close: 10.25, AdjClose: 10.25, Volume: 6941580
        }, {
            date: new Date("2023-02-08"), Open: 10.5, High: 10.5, Low: 9.75, Close: 9.75, AdjClose: 9.75, Volume: 5068808
        }, {
            date: new Date("2023-02-09"), Open: 10, High: 10, Low: 8.75, Close: 9, AdjClose: 9, Volume: 5507308
        }, {
            date: new Date("2023-02-10"), Open: 8.75, High: 9.25, Low: 8.25, Close: 9, AdjClose: 9, Volume: 4441764
        }, {
            date: new Date("2023-02-13"), Open: 9.25, High: 10, Low: 9, Close: 9.5, AdjClose: 9.5, Volume: 4399924
        }, {
            date: new Date("2023-02-14"), Open: 9.5, High: 9.75, Low: 9.25, Close: 9.5, AdjClose: 9.5, Volume: 3561964
        }, {
            date: new Date("2023-02-15"), Open: 9, High: 9, Low: 8.5, Close: 8.5, AdjClose: 8.5, Volume: 4845884
        }, {
            date: new Date("2023-02-16"), Open: 8.5, High: 8.5, Low: 7.25, Close: 7.25, AdjClose: 7.25, Volume: 8802076
        }, {
            date: new Date("2023-02-17"), Open: 7.5, High: 7.75, Low: 6.5, Close: 6.75, AdjClose: 6.75, Volume: 10437316
        }, {
            date: new Date("2023-02-21"), Open: 6.5, High: 6.75, Low: 6, Close: 6.25, AdjClose: 6.25, Volume: 7545448
        }, {
            date: new Date("2023-02-22"), Open: 6.5, High: 6.75, Low: 6.25, Close: 6.5, AdjClose: 6.5, Volume: 5861328
        }, {
            date: new Date("2023-02-23"), Open: 6.25, High: 6.25, Low: 5.75, Close: 6, AdjClose: 6, Volume: 7337504
        }, {
            date: new Date("2023-02-24"), Open: 5.75, High: 6, Low: 5.75, Close: 5.75, AdjClose: 5.75, Volume: 4474744
        }, {
            date: new Date("2023-02-27"), Open: 5.75, High: 6, Low: 5.25, Close: 5.5, AdjClose: 5.5, Volume: 7278080
        }, {
            date: new Date("2023-02-28"), Open: 5.5, High: 6.25, Low: 5.25, Close: 5.75, AdjClose: 5.75, Volume: 9534396
        }, {
            date: new Date("2023-03-01"), Open: 5.75, High: 5.75, Low: 5.5, Close: 5.5, AdjClose: 5.5, Volume: 5278352
        }, {
            date: new Date("2023-03-02"), Open: 5.5, High: 5.5, Low: 5.25, Close: 5.25, AdjClose: 5.25, Volume: 4641524
        }, {
            date: new Date("2023-03-03"), Open: 5.5, High: 5.5, Low: 5.25, Close: 5.5, AdjClose: 5.5, Volume: 5799376
        }, {
            date: new Date("2023-03-06"), Open: 5.75, High: 6, Low: 5.75, Close: 5.75, AdjClose: 5.75, Volume: 10149876
        }, {
            date: new Date("2023-03-07"), Open: 5.75, High: 5.75, Low: 5.25, Close: 5.25, AdjClose: 5.25, Volume: 10127516
        }, {
            date: new Date("2023-03-08"), Open: 5, High: 5.25, Low: 4.75, Close: 4.75, AdjClose: 4.75, Volume: 10495128
        }, {
            date: new Date("2023-03-09"), Open: 4.75, High: 5, Low: 4.5, Close: 4.5, AdjClose: 4.5, Volume: 10362552
        }, {
            date: new Date("2023-03-10"), Open: 4.5, High: 4.75, Low: 4, Close: 4.25, AdjClose: 4.25, Volume: 11596560
        }, {
            date: new Date("2023-03-13"), Open: 4, High: 4.25, Low: 3.75, Close: 4, AdjClose: 4, Volume: 8486408
        }, {
            date: new Date("2023-03-14"), Open: 4.25, High: 4.25, Low: 3.75, Close: 4, AdjClose: 4, Volume: 7200112
        }, {
            date: new Date("2023-03-15"), Open: 3.75, High: 3.75, Low: 3.25, Close: 3.75, AdjClose: 3.75, Volume: 12396180
        }, {
            date: new Date("2023-03-16"), Open: 3.5, High: 4, Low: 3.5, Close: 3.75, AdjClose: 3.75, Volume: 10020624
        }, {
            date: new Date("2023-03-17"), Open: 3.75, High: 3.75, Low: 3.25, Close: 3.5, AdjClose: 3.5, Volume: 16559792
        }, {
            date: new Date("2023-03-20"), Open: 3.5, High: 3.5, Low: 3.25, Close: 3.25, AdjClose: 3.25, Volume: 11500368
        }, {
            date: new Date("2023-03-21"), Open: 3.25, High: 3.5, Low: 3.25, Close: 3.5, AdjClose: 3.5, Volume: 9211864
        }, {
            date: new Date("2023-03-22"), Open: 3.5, High: 3.5, Low: 3, Close: 3, AdjClose: 3, Volume: 16629592
        }, {
            date: new Date("2023-03-23"), Open: 3, High: 3, Low: 2.75, Close: 2.75, AdjClose: 2.75, Volume: 13522776
        }, {
            date: new Date("2023-03-24"), Open: 2.75, High: 2.75, Low: 2.5, Close: 2.75, AdjClose: 2.75, Volume: 7062912
        }, {
            date: new Date("2023-03-27"), Open: 2.75, High: 3, Low: 2.5, Close: 2.5, AdjClose: 2.5, Volume: 16872032
        }, {
            date: new Date("2023-03-28"), Open: 2.5, High: 2.5, Low: 2.25, Close: 2.25, AdjClose: 2.25, Volume: 18085596
        }, {
            date: new Date("2023-03-29"), Open: 2.5, High: 2.75, Low: 2.5, Close: 2.5, AdjClose: 2.5, Volume: 13087328
        }, {
            date: new Date("2023-03-30"), Open: 3, High: 3.25, Low: 2.75, Close: 3, AdjClose: 3, Volume: 15906408
        }, {
            date: new Date("2023-03-31"), Open: 3.75, High: 4, Low: 3.25, Close: 3.25, AdjClose: 3.25, Volume: 31149784
        }, {
            date: new Date("2023-04-03"), Open: 3.5, High: 3.5, Low: 2.75, Close: 2.75, AdjClose: 2.75, Volume: 22327388
        }, {
            date: new Date("2023-04-04"), Open: 2.75, High: 3, Low: 2.5, Close: 2.75, AdjClose: 2.75, Volume: 10406968
        }, {
            date: new Date("2023-04-05"), Open: 2.5, High: 2.75, Low: 2.5, Close: 2.5, AdjClose: 2.5, Volume: 9691656
        }, {
            date: new Date("2023-04-06"), Open: 2.5, High: 2.5, Low: 2.5, Close: 2.5, AdjClose: 2.5, Volume: 7223384
        }, {
            date: new Date("2023-04-10"), Open: 2.5, High: 2.75, Low: 2.5, Close: 2.75, AdjClose: 2.75, Volume: 8639568
        }, {
            date: new Date("2023-04-11"), Open: 2.75, High: 2.75, Low: 2.75, Close: 2.75, AdjClose: 2.75, Volume: 7052360
        }, {
            date: new Date("2023-04-12"), Open: 2.75, High: 3, Low: 2.5, Close: 2.75, AdjClose: 2.75, Volume: 5582208
        }, {
            date: new Date("2023-04-13"), Open: 2.5, High: 2.5, Low: 2.5, Close: 2.5, AdjClose: 2.5, Volume: 5076904
        }, {
            date: new Date("2023-04-14"), Open: 2.5, High: 2.5, Low: 2.5, Close: 2.5, AdjClose: 2.5, Volume: 6879540
        }, {
            date: new Date("2023-04-17"), Open: 2.5, High: 2.75, Low: 2.5, Close: 2.5, AdjClose: 2.5, Volume: 8328696
        }, {
            date: new Date("2023-04-18"), Open: 2.5, High: 2.75, Low: 2.25, Close: 2.25, AdjClose: 2.25, Volume: 8404616
        }, {
            date: new Date("2023-04-19"), Open: 2.25, High: 2.25, Low: 2.25, Close: 2.25, AdjClose: 2.25, Volume: 10672816
        }, {
            date: new Date("2023-04-20"), Open: 2.25, High: 2.25, Low: 2, Close: 2, AdjClose: 2, Volume: 9434960
        }, {
            date: new Date("2023-04-21"), Open: 2.75, High: 2.75, Low: 2.5, Close: 2.5, AdjClose: 2.5, Volume: 28932644
        }, {
            date: new Date("2023-04-24"), Open: 3, High: 3, Low: 2.25, Close: 2.5, AdjClose: 2.5, Volume: 30287504
        }, {
            date: new Date("2023-04-25"), Open: 2.25, High: 2.25, Low: 2, Close: 2.25, AdjClose: 2.25, Volume: 15331432
        }, {
            date: new Date("2023-04-26"), Open: 2.25, High: 2.25, Low: 2, Close: 2, AdjClose: 2, Volume: 16959568
        }, {
            date: new Date("2023-04-27"), Open: 2, High: 2, Low: 1.75, Close: 1.75, AdjClose: 1.75, Volume: 24142148
        }, {
            date: new Date("2023-04-28"), Open: 1.75, High: 2, Low: 1.75, Close: 2, AdjClose: 2, Volume: 26691788
        }, {
            date: new Date("2023-05-01"), Open: 2, High: 2.25, Low: 1.75, Close: 1.75, AdjClose: 1.75, Volume: 19451624
        }, {
            date: new Date("2023-05-02"), Open: 2, High: 2.5, Low: 1.75, Close: 2, AdjClose: 2, Volume: 22307068
        }, {
            date: new Date("2023-05-03"), Open: 2.25, High: 2.25, Low: 1.5, Close: 1.5, AdjClose: 1.5, Volume: 4655080
        }, {
            date: new Date("2023-05-04"), Open: 1.46, High: 1.55, Low: 1.26, Close: 1.47, AdjClose: 1.47, Volume: 44482700
        }, {
            date: new Date("2023-05-05"), Open: 1.47, High: 1.53, Low: 1.42, Close: 1.5, AdjClose: 1.5, Volume: 18002100
        }, {
            date: new Date("2023-05-08"), Open: 1.61, High: 1.62, Low: 1.38, Close: 1.48, AdjClose: 1.48, Volume: 28519100
        }, {
            date: new Date("2023-05-09"), Open: 1.43, High: 1.45, Low: 1.37, Close: 1.44, AdjClose: 1.44, Volume: 15441600
        }, {
            date: new Date("2023-05-10"), Open: 1.44, High: 1.47, Low: 1.37, Close: 1.39, AdjClose: 1.39, Volume: 11551900
        }, {
            date: new Date("2023-05-11"), Open: 1.46, High: 1.47, Low: 1.38, Close: 1.4, AdjClose: 1.4, Volume: 18973500
        }, {
            date: new Date("2023-05-12"), Open: 1.36, High: 1.37, Low: 1.21, Close: 1.27, AdjClose: 1.27, Volume: 21184200
        }, {
            date: new Date("2023-05-15"), Open: 1.27, High: 1.28, Low: 1.22, Close: 1.25, AdjClose: 1.25, Volume: 14834100
        }, {
            date: new Date("2023-05-16"), Open: 1.26, High: 1.27, Low: 1.18, Close: 1.22, AdjClose: 1.22, Volume: 21914100
        }, {
            date: new Date("2023-05-17"), Open: 1.19, High: 1.49, Low: 1.17, Close: 1.23, AdjClose: 1.23, Volume: 57503500
        }, {
            date: new Date("2023-05-18"), Open: 1.24, High: 1.25, Low: 1.16, Close: 1.19, AdjClose: 1.19, Volume: 28176700
        }, {
            date: new Date("2023-05-19"), Open: 1.1, High: 1.11, Low: 1.01, Close: 1.07, AdjClose: 1.07, Volume: 34265100
        }, {
            date: new Date("2023-05-22"), Open: 1.04, High: 1.04, Low: 0.93, Close: 0.95, AdjClose: 0.95, Volume: 63233800
        }, {
            date: new Date("2023-05-23"), Open: 0.92, High: 0.94, Low: 0.88, Close: 0.91, AdjClose: 0.91, Volume: 41968500
        }, {
            date: new Date("2023-05-24"), Open: 0.87, High: 0.87, Low: 0.8, Close: 0.8, AdjClose: 0.8, Volume: 43785400
        }, {
            date: new Date("2023-05-25"), Open: 0.75, High: 0.99, Low: 0.72, Close: 0.95, AdjClose: 0.95, Volume: 92726500
        }, {
            date: new Date("2023-05-26"), Open: 0.93, High: 0.93, Low: 0.8, Close: 0.81, AdjClose: 0.81, Volume: 55754600
        }, {
            date: new Date("2023-05-30"), Open: 0.86, High: 0.89, Low: 0.73, Close: 0.76, AdjClose: 0.76, Volume: 77086100
        }, {
            date: new Date("2023-05-31"), Open: 0.73, High: 0.76, Low: 0.72, Close: 0.73, AdjClose: 0.73, Volume: 27207900
        }, {
            date: new Date("2023-06-01"), Open: 0.72, High: 0.79, Low: 0.68, Close: 0.74, AdjClose: 0.74, Volume: 39515700
        }, {
            date: new Date("2023-06-02"), Open: 0.72, High: 0.77, Low: 0.72, Close: 0.75, AdjClose: 0.75, Volume: 16639000
        }, {
            date: new Date("2023-06-05"), Open: 0.73, High: 0.74, Low: 0.68, Close: 0.7, AdjClose: 0.7, Volume: 31511400
        }, {
            date: new Date("2023-06-06"), Open: 0.66, High: 0.66, Low: 0.61, Close: 0.61, AdjClose: 0.61, Volume: 48116400
        }, {
            date: new Date("2023-06-07"), Open: 0.56, High: 0.56, Low: 0.51, Close: 0.52, AdjClose: 0.52, Volume: 78231800
        }, {
            date: new Date("2023-06-08"), Open: 0.51, High: 0.54, Low: 0.46, Close: 0.47, AdjClose: 0.47, Volume: 64453200
        }, {
            date: new Date("2023-06-09"), Open: 0.48, High: 0.49, Low: 0.42, Close: 0.43, AdjClose: 0.43, Volume: 69565100
        }, {
            date: new Date("2023-06-12"), Open: 0.42, High: 0.42, Low: 0.38, Close: 0.39, AdjClose: 0.39, Volume: 65200300
        }, {
            date: new Date("2023-06-13"), Open: 0.36, High: 0.36, Low: 0.32, Close: 0.32, AdjClose: 0.32, Volume: 100073800
        }, {
            date: new Date("2023-06-14"), Open: 0.29, High: 0.29, Low: 0.22, Close: 0.23, AdjClose: 0.23, Volume: 172612100
        }, {
            date: new Date("2023-06-15"), Open: 0.23, High: 0.3, Low: 0.2, Close: 0.26, AdjClose: 0.26, Volume: 399766300
        }, {
            date: new Date("2023-06-16"), Open: 0.28, High: 0.3, Low: 0.22, Close: 0.23, AdjClose: 0.23, Volume: 291824400
        }, {
            date: new Date("2023-06-20"), Open: 0.24, High: 0.24, Low: 0.16, Close: 0.16, AdjClose: 0.16, Volume: 342483000
        }, {
            date: new Date("2023-06-21"), Open: 0.18, High: 0.22, Low: 0.17, Close: 0.2, AdjClose: 0.2, Volume: 449997300
        }, {
            date: new Date("2023-06-22"), Open: 0.19, High: 0.2, Low: 0.17, Close: 0.18, AdjClose: 0.18, Volume: 236068800
        }, {
            date: new Date("2023-06-23"), Open: 0.17, High: 0.19, Low: 0.16, Close: 0.17, AdjClose: 0.17, Volume: 144855400
        }, {
            date: new Date("2023-06-26"), Open: 0.16, High: 0.2, Low: 0.16, Close: 0.17, AdjClose: 0.17, Volume: 205901200
        }, {
            date: new Date("2023-06-27"), Open: 0.18, High: 0.18, Low: 0.14, Close: 0.14, AdjClose: 0.14, Volume: 433085400
        }, {
            date: new Date("2023-06-28"), Open: 0.12, High: 0.13, Low: 0.1, Close: 0.11, AdjClose: 0.11, Volume: 409336900
        }, {
            date: new Date("2023-06-29"), Open: 0.12, High: 0.13, Low: 0.11, Close: 0.12, AdjClose: 0.12, Volume: 387332500
        }, {
            date: new Date("2023-06-30"), Open: 0.11, High: 0.11, Low: 0.1, Close: 0.11, AdjClose: 0.11, Volume: 280823700
        }, {
            date: new Date("2023-07-03"), Open: 0.11, High: 0.12, Low: 0.1, Close: 0.1, AdjClose: 0.1, Volume: 230239800
        }, {
            date: new Date("2023-07-05"), Open: 0.1, High: 0.18, Low: 0.1, Close: 0.17, AdjClose: 0.17, Volume: 1357185400
        }, {
            date: new Date("2023-07-06"), Open: 0.28, High: 0.32, Low: 0.2, Close: 0.22, AdjClose: 0.22, Volume: 1894471800
        }, {
            date: new Date("2023-07-07"), Open: 0.23, High: 0.23, Low: 0.19, Close: 0.19, AdjClose: 0.19, Volume: 743754900
        }, {
            date: new Date("2023-07-10"), Open: 0.18, High: 0.22, Low: 0.18, Close: 0.19, AdjClose: 0.19, Volume: 593377300
        }, {
            date: new Date("2023-07-11"), Open: 0.19, High: 0.21, Low: 0.18, Close: 0.18, AdjClose: 0.18, Volume: 340592600
        }, {
            date: new Date("2023-07-12"), Open: 0.17, High: 0.17, Low: 0.14, Close: 0.14, AdjClose: 0.14, Volume: 583237200
        }, {
            date: new Date("2023-07-13"), Open: 0.16, High: 0.16, Low: 0.14, Close: 0.16, AdjClose: 0.16, Volume: 413955300
        }, {
            date: new Date("2023-07-14"), Open: 0.16, High: 0.18, Low: 0.15, Close: 0.16, AdjClose: 0.16, Volume: 393988000
        }, {
            date: new Date("2023-07-17"), Open: 0.16, High: 0.17, Low: 0.16, Close: 0.16, AdjClose: 0.16, Volume: 256035100
        }, {
            date: new Date("2023-07-18"), Open: 0.16, High: 0.16, Low: 0.15, Close: 0.16, AdjClose: 0.16, Volume: 223916300
        }, {
            date: new Date("2023-07-19"), Open: 0.15, High: 0.15, Low: 0.14, Close: 0.15, AdjClose: 0.15, Volume: 257652600
        }, {
            date: new Date("2023-07-20"), Open: 0.15, High: 0.16, Low: 0.14, Close: 0.15, AdjClose: 0.15, Volume: 246053500
        }, {
            date: new Date("2023-07-21"), Open: 0.15, High: 0.15, Low: 0.14, Close: 0.14, AdjClose: 0.14, Volume: 224178400
        }],
    keys: {
        x: 'date', // it's possible to specify 'x' when category axis
        // xFormat: d3.timeFormat("%Y-%m-%d"),
        value: ['High', 'Low'],
    },
    type: 'stanford',

    axis: {
        x: {
            type: 'category',
            tick: {
                format: '%Y-%m-%d'
            }
        }
    }
};
// const data =  {
//     columns: [
//         ['data1', 300, 350, 300, 0, 0, 100],
//         ['data2', 130, 100, 140, 200, 150, 50]
//     ],
//         types: {
//         data1: 'step',
//             data2: 'area-step'
//     }
// };

let demoData = data.json.map(datapoint => {
    let returnD = {};
    returnD.x = datapoint.date;
    returnD.y = [datapoint.Open, datapoint.High, datapoint.Low, datapoint.Close];
    return returnD;
    //     x : new Date(datapoint.Date),
    // y:[ datapoint.Open, datapoint.High, datapoint.Low, datapoint.Close]})
    //         x: new Date(1538778600000),
    //         y: [6629.81, 6650.5, 6623.04, 6633.33]
});



export default class Stocks extends React.Component {


    constructor() {
        super();
        this.state = {
            series: [{
                name: 'candle',
                data: demoData
            }],
            options: {
                chart: {
                    height: '100vh',
                    type: 'candlestick',
                    toolbar: false,
                    zoom: {
                        enabled: true,
                        type: 'x',
                        autoScaleYaxis: true,
                        zoomedArea: {
                            fill: {
                                color: '#90CAF9',
                                opacity: 0.4
                            },
                            stroke: {
                                color: '#0D47A1',
                                opacity: 0.4,
                                width: 1
                            }
                        }
                    }
                },
                title: {
                    text: 'Candlestick',
                    align: 'left'
                },

                annotations: {
                    xaxis: [
                        {
                            x: 'Oct 06 14:00',
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                style: {
                                    fontSize: '12px',
                                    color: '#fff',
                                    background: '#00E396'
                                },
                                orientation: 'horizontal',
                                // offsetY: 7,
                                text: 'Annotation Test'
                            }
                        }
                    ]
                },
                tooltip: {
                    enabled: true,
                },
                xaxis: {
                    type: 'category',
                    labels: {
                        rotate: 0,
                        formatter: function (val) {
                            return dayjs(val).format('YY-MM-DD')
                        }
                    },
                    group: {
                        groups: [],
                        style: {
                            colors: [],
                            fontSize: '12px',
                            fontWeight: 400,
                            fontFamily: undefined,
                            cssClass: ''
                        }
                    },
                },
                yaxis: {

                }
            },


        };

    }

    wordCount = (doc) => {
        return doc.length;
    }

    sentenceCount = doc => {
        let sentences = doc.split(". ");
        return sentences;
    }

    render() {
        return <div className={"component"} style={{ minHeight: "100vh" }}>
            <div className={"stocks"}>
                <img className="bull" src={bull} />
                <img className="bear" src={bear} />

            </div>


            <div className={"contentContainer"}>

                <Slide top>
                    <h2>
                        <img src={"https://logos.stockanalysis.com/muln.svg"} width={"100px"} style={{ verticalAlign: 'middle' }} /> Mullen Automotive Inc.(MULN)
                    </h2>
                    <i> NEW DELHI, APRIL 22, 2020 11:30 IST </i>
                </Slide>

                <Slide top>
                    Total Valuation
                    MULN has a market cap or net worth of $90.07 million. The enterprise value is $43.72 million.

                    <p><i>Market Cap 90.07M</i></p>
                    <p><i>Enterprise Value 43.72M</i></p>
                </Slide>
                <Slide>
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="candlestick"
                        width="100%"
                    />
                </Slide>


                <div className={"articleContent"}>

                    <div className={"articleSpacer"}></div>

                    {this.state.data}
                </div>
                Working example : https://github.com/alvin-yang68/Financial-Candlestick-Chart/tree/main/src/charts
            </div>


        </div>
    }
}
