import React from 'react';
import "/node_modules/flag-icons/css/flag-icons.min.css";

export default class ComponentA extends React.Component {
  render() {
    return <div >
        <h2> RSS Reader</h2>
        <iframe title="New York Times Video - Embed Player" width={document.body.clientWidth} height={0.6 * document.body.clientWidth} frameBorder="0" scrolling="no" allowFullScreen="true" marginHeight="0" marginWidth="0" id="nyt_video_player" src="https://www.nytimes.com/video/players/offsite/index.html?videoId=100000006911696"></iframe>
      </div>
  }
}
