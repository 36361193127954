import React from 'react';
import "../my.scss";
import {FaHeart} from 'react-icons/fa';

export default class HindiNews extends React.Component {
    render() {
        return <div className={"component"} style={{minHeight: "100vh"}}>
            <div className={"thehindu"}>
                <FaHeart/>90%
                <img src="https://www.thehindu.com/theme/images/th-online/thehindu-logo.svg" alt={""} />
            </div>
            <div className={"contentContainer"}>

                <h2>
                    इंडस्ट्री के लिए राहत पैकेज पर लगेगी मुहर! पीएम मोदी की वित्त मंत्री के साथ बैठक आज
                </h2>
                <i>NEW DELHI, APRIL 22, 2020 11:30 IST
                </i>
                <p>
                    <img src="https://www.thehindu.com/news/national/5mqwd5/article31402887.ece/ALTERNATES/FREE_960/AMITSHAH" alt={""}/>
                </p>
                <div>
                    <p>
                        वरिष्ठ सरकारी सूत्र इस बात की पुष्टि करते हैं कि इंडस्ट्री के लिए पैकेज का प्रस्ताव और निहितार्थ मार्च में प्रधानमंत्री कल्याण योजना के तहत घोषित 1.7 लाख करोड़ रुपये के पैकेज से बड़ा हो सकता है. इस पैकेज में लॉकडाउन और कोविड-19 का अर्थव्यवस्था पर पड़ने वाले असर से अर्थव्यवस्था को राहत देने, पुनर्वास और उसे उबारने पर जोर हो सकता है.
                    </p>
                    <p>
                        वरिष्ठ सरकारी सूत्र इस बात की पुष्टि करते हैं कि &apos;इस पैकेज का प्रस्ताव और निहितार्थ&apos; मार्च में प्रधानमंत्री कल्याण योजना के तहत घोषित 1.7 लाख करोड़ रुपये के पैकेज से बड़ा हो सकता है. सूत्रों के अनुसार इस पैकेज में लॉकडाउन और कोविड-19 का अर्थव्यवस्था पर पड़ने वाले असर से अर्थव्यवस्था को राहत देने, पुनर्वास और उसे उबारने पर जोर हो सकता है. लगातार बदलते हालात के बीच पीएमओ सभी संबंधित पक्षों और सलाहकार संस्थाओं से चर्चा करता रहा है.
                    </p>
                </div>

            </div>

        </div>
    }
}
