import React from 'react';
import "../my.scss";
import {FaHeart} from 'react-icons/fa';

export default class VanityFair extends React.Component {
    render() {
        return <div className={"component"} style={{minHeight: "100vh"}}>
            <div className={"vanityfair"}>
                <FaHeart/>90%
                <img src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.HSRaDKxpFK7l547NT1WnDwHaDI%26pid%3DApi&f=1" alt={""}/>
            </div>
            <div className={"contentContainer"}>
                <p>
                    <span className={"start"}> B</span>eastie Boys Story has a little bit of everything. Billed as a “live documentary,” the film—out Friday on Apple TV+—is a companion to Beastie Boys Book, written by Adam “Ad-Rock” Horovitz and Mike Diamond, better known as Mike D—a massive 590 pages crammed with in-jokes, essays, maps, lists, recipes, memories, and amazingly candid photos. The movie, directed by Beastie Boys’ longtime collaborator and friend Spike Jonze, was shot last year, over the course of three nights at the Kings Theatre in Brooklyn. But it has the feel of a “one-night-only” special: Cues are botched, marks are missed, and some (admittedly lame) jokes don’t land.

                </p>
                <p><span className={"paragraph"}> T</span>herein lies the charm of Beastie Boys Story, and of the Beastie Boys’ four-decade career. They started as Budweiser-swilling party monsters; they grew into introspective, respected elders of the hip-hop scene, without ever losing their sense of humor.

                </p>
                <p><span className={"paragraph"}>T</span>he only pallor hanging over the proceedings is the early death of the third Beastie Boy, Adam “MCA” Yauch—who lost his life after a nearly three-year cancer battle in 2012. It was Yauch’s vision that helped form the group; in the film, Horovitz gets choked up remembering the 2009 Bonnaroo performance that would become their last.

                </p>
                <p><span className={"paragraph"}>I</span> spoke with Horovitz, Diamond, and Jonze via Zoom, for a rollicking, 55-minute interview that was a bit like moderating a reunion between three old college friends—or three littermate puppies that have been separated for the day. After several wardrobe changes and Zoom backdrop adjustments, our conversation veered from Yauch’s culinary skills to Madonna, the 1997 Bulls to Mike D’s desire to make a documentary about farts.

                </p>
                <p><span className={"paragraph"}>W</span>atch Beastie Boys Story:
                </p>
                <p><span className={"paragraph"}>V</span>anity Fair: Spike, tell me about the day you met Adam and Mike. What clicked?

                </p>
                <p><span className={"paragraph"}>S</span>pike Jonze: I was shooting photos of them for a magazine that me and my friends had, called Dirt, in 1991. We were recording the interview on a cassette recorder, and they brought one too. They said, “We’re going to record this interview too, to make sure our quotes are printed correctly. Jerry Lewis used to do it, and he told us to do it too.”

                    Mike Diamond: I’d like to talk about what didn’t click right away with Spike [laughs]. It’s our own fault, really. We were about 21 when we met Spike, and he was only 19. When you’re 21, and we had Licensed to Ill come out—which was incredibly successful and sold millions of copies—you kinda think you’re the shit. We were in Los Angeles, recording our follow-up, Paul’s Boutique, and we were still jerks a little bit. We showed up to do this magazine photo shoot, and they sent us this kid. “How dare they send a kid to shoot us!”

                    You wanted Annie Leibovitz?

                </p>
                <p><span className={"paragraph"}>D</span>iamond: At that point, we certainly didn’t know who Annie Leibovitz was. We were just expecting someone in leather riding boots with eight assistants. It was just Spike, this kid with a huge camera bag and tons of equipment.

                </p>
                <p><span className={"paragraph"}>T</span>o Spike’s credit, he has this thing where he’s very unflappable. We used to put him through these mini-tests, or mini-quests, of fucking with him. Nothing threw him off. We also had a lot of shared, cultural reference points, coming from punk rock and skater culture. That stuff was so important to us.

                </p>
                <p><span className={"paragraph"}>A</span>dam Horovitz: He was always just around. This guy, again? Ahh…ok.

                </p>
                <p><span className={"paragraph"}>J</span>onze: That was 95% of it, probably.

                </p>
                <p><span className={"paragraph"}>P</span>art of the charm of the documentary is that when a cue is missed, or a joke doesn’t land, you show your nerves. Is this the first time you’ve felt nervous onstage since the early days?

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: It was out of our comfort zone, for sure, but I wasn’t nervous to be doing stupid shit onstage. That’s easy. In our lives, we all tell the same stories over and over to our friends. But when you write it down, then have to stick to a script and say it out loud to a bunch of people, it’s weird. We’re not necessarily very good at it.

                </p>
                <p><span className={"paragraph"}>Y</span>ou weren’t nervous on your first tour opening for Madonna?

                </p>
                <p><span className={"paragraph"}> D</span>iamond: No, because we had a plan, which was just a full-on assault on the audience. We wanted to make them hate us as much as we could in 10 minutes. We were pretty good at executing that plan.

                </p>
                <p><span className={"paragraph"}>B</span>eing in a band gives you confidence, because you’re like a team, or a gang. There is strength in those numbers. Every time we played Madison Square Garden, which is the place that all of us grew up going to see the circus or Knicks games as little kids, it was always a huge deal, and that made us a little nervous. Bottom line, for decades as friends, we got very comfortable playing music in front of an audience. What Adam and I realized playing the Kings Theatre for the documentary was that we were using a completely different part of our brains, having to stick to the script.

                </p>
                <p><span className={"paragraph"}>T</span>he documentary spans several decades. Do any parts of it make you cringe now?

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: It wasn’t so much the fashion in the ’80s, because we looked great. It was mostly the things we were saying and doing during that time that makes me cringe. It’s one thing to look back on mistakes you’ve made, which is important to do so you can move forward. It’s a completely different feeling to do that in front of a massive screen showing me being a fucking idiot, in front of a thousand people. Add in the fact that we were working with such a tremendous filmmaker like Spike Jonze—that’s what made me nervous. If I didn’t hit my mark onstage, I’d never be able to work in this town again.

                </p>
                <p><span className={"paragraph"}>S</span>pike directed several of your music videos, including “Sabotage.” Who came up with the idea of doing a ’70s cop theme?

                </p>
                <p><span className={"paragraph"}>J</span>onze: The band had always wanted me to shoot photos of them as cops. I remember I had grown a mustache; I tried to talk you guys into growing them too. I give Adam credit because he’s the only one that actually [did]. We had to buy a bunch of fake ones from the East Side mustache district. I don’t know if you know this, but there’s a whole mustache district in East Los Angeles.

                </p>
                <p><span className={"paragraph"}>I</span> want to go back to Madonna for a minute…

                    Horovitz: What is it with you and Madonna?

                </p>
                <p><span className={"paragraph"}>D</span>iamond: Have you written a lot of features about Madonna? Have you talked to a therapist about her? What’s the deal?

                </p>
                <p><span className={"paragraph"}>I</span> want to know if there are any celebrities that have left you starstruck.

                </p>
                <p><span className={"paragraph"}>J</span>onze: I get starstruck with Adam Horovitz a lot. It’s intense. Adam is hot in a way that is hard to comprehend.

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: Yep. I just ooze heat.

                </p>
                <p><span className={"paragraph"}>D</span>iamond: Adam has a great starstruck story from when we were on tour. Adam went up to the pool or the gym of whatever hotel we were at, and saw Shaquille O’Neal. Instead of saying, “Hi, I’m Adam Horovitz from Beastie Boys,” he pointed at Shaq and squealed, “Ooh-Ooh-Ooh!”

                    Have you been watching ESPN’s The Last Dance, a documentary about Michael Jordan’s final season with the Chicago Bulls, in quarantine?

                </p>
                <p><span className={"paragraph"}>D</span>iamond: I was watching episode two last night, and what I found really interesting is that there’s so much timeline crossover. During the decades when they were winning championships, we were putting out records and touring. We were playing the sports arenas where they played the same week. I realized that there’s such a fragile chemistry between a basketball team and management, and all the pieces that make a band. It’s very different personalities from very different backgrounds, all having to make a team work. It’s a very fragile, little ecosystem.

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: Who do you see yourself as on that Bulls team, Mike?

                </p>
                <p><span className={"paragraph"}>D</span>iamond: Scottie Pippen, 100%. I’m underpaid. You’re B.J. Armstrong, Adam. You’ve got a good-looking dribble and shot.

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: Fuck you, Mike.

                </p>
                <p><span className={"paragraph"}>I</span>s there anything you wanted as a band, but never got—maybe a project or collaboration that never happened? Or did you leave it all on the court?

                </p>
                <p><span className={"paragraph"}>D</span>iamond: One thing that led to us doing the book was that Yauch always wanted to do a The Kids Are Alright–type documentary of the band, using archival footage. Sadly, he didn’t live to execute that, but I think we’ve checked that box with the book and documentary.

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: This is a bummer, but it’s true. On our last record [2011’s Hot Sauce Committee Part Two] we had a song called “Make Some Noise.” For us, it was our best instrumental playing and rap song. It was the best combination of the two—but we never got to perform that song live. I really would have liked to have done that.

                </p>
                <p><span className={"paragraph"}>D</span>o you get hip-hop in 2020, or have you aged out of it?

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: There’s a lot of great rap music being made, and it’s interesting to hear what producers and artists are doing with the instruments that we used, and how they’re doing it now. I can’t listen to a song and not notice that they’ve got hi-hats going on 32 notes, and where the bass comes in. Listening to a song is different for us.

                </p>
                <p><span className={"paragraph"}>D</span>iamond: It’s about craft. Us listening to a record is not like someone else listening to that record. We’re analyzing how they’re approaching it, and what’s going into making it. I have
                    friends working on the new Kendrick Lamar, so I’m looking forward to that. That’s going to be an exciting record.

                </p>
                <p><span className={"paragraph"}>D</span>id Yauch always seem like an old soul, even as a kid?

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: I don’t know about old soul, but he always knew things. He knew things that I had never even thought about. A long time ago as kids, we were running through the streets at night, and I was diving on trash bags, because that’s what you did as a New York kid. Yauch was like, “Oh, you shouldn’t do that again.” I asked why, and he said, “Because there could be broken glass in there.” I had never thought about that. Even as kids, he was a really good cook. He was a different type of person.

                </p>
                <p><span className={"paragraph"}>D</span>iamond: That’s very true. If we were eating at each other’s houses, which we did a lot as high school kids, I’d know how to put together a grilled cheese. Yauch would say, “Tonight I’m making chicken cordon bleu.” Horovitz and I were the youngest of three siblings, and Yauch was an only child, so he had the space to fulfill his own curiosity. Horovitz and I were fighting for our share of food and attention.

                </p>
                <p><span className={"paragraph"}>B</span>etween you three and Yauch, was there ever a clear, democratic decision about who is the funniest? Or is it always a game of one-upmanship?

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: It’s definitely a game of one-upping each other, for sure.

                </p>

                <p><span className={"paragraph"}> D</span>iamond: I don’t know how people make records and films if they’re not friends. We always made our records for each other. We always had each other as the audience. For me, it was a challenge of trying to crack up these other two people in the room. Working on this documentary, I felt the same thing with Adam and Spike.

                </p>
                <p><span className={"paragraph"}>J</span>onze: I picture you guys like a volleyball team in short-shorts, where one of you lobs the ball, and the other spikes. But the main thing is that you always look great in short-shorts.

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: Honestly, I’m not sure if Mike is that funny. When Mike is about to say something that he thinks is funny, it takes him 42 minutes to spit it out, because he’s laughing to himself so hard that tears come down his face. When he finally gets it out, he says something like, “I farted.” That’s the joke.

                </p>
                <p><span className={"paragraph"}>D</span>iamond: Which is funny! There should be a documentary about farts.

                </p>
                <p><span className={"paragraph"}>L</span>et’s end this like a therapy session. Mike, what do you still find fascinating about Adam after all these years? What keeps the relationship fresh?

                </p>
                <p><span className={"paragraph"}>D</span>iamond: Adam will always, relentlessly, diss the shit out of me every single time. He does it in a way that nobody else could, because he’s known me for longer than anybody else. He will automatically cut to the chase, and just lay into me every time.

                </p>
                <p><span className={"paragraph"}>H</span>orovitz: Do you remember those cartoons where the baby is walking around the construction site, and is about to fall off the building but lands on a girder that carries him to safety? That’s Mike, and he’s still like that. It’s fascinating. He’s a miraculously bulletproof baby.
                </p>
            </div>

        </div>
    }
}
