import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import ComponentA from './components/ComponentA';
import ComponentB from './components/ComponentB';
import NewsBoard from "./components/NewsBoard";
import HindiNews from "./components/HindiNews";
import AjitTemplate from "./components/AjitTemplate";
import NerdStats from "./components/NerdStats.js";
import VanityFair from "./components/VanityFair.js";
import Stocks from "./components/Stocks.js";
import App from "./App.js";
import {Menu} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import Slideshow from "./components/Slideshow.js";
import logo from "./logo.svg"

export default class RoutesApp extends React.Component {
  state = {activeItem: 'home'}

  handleItemClick = (e, {name}) => {
    this.setState({activeItem: name})
  }

  render() {
    const {activeItem} = this.state
    return (
      <Router>
        <Menu pointing secondary>
          <Menu.Item
            as={Link}
            to="/"
            name='Open Logue'
            active={activeItem === 'Open Logue'}
            onClick={this.handleItemClick}
          >
            <img src={logo} title={'Open Logue'}/>
            Open Logue
          </Menu.Item>

          <Menu.Item
            as={Link} to="/newsboard"
            name='General Newsboard'
            active={activeItem === 'General Newsboard'}
            onClick={this.handleItemClick}
          >
            General Newsboard
          </Menu.Item>

          <Menu.Item
            as={Link} to="/the-hindu/IMA-withdraws-planned-protest-after-assurance-from-Amit-Shah"
            name='ComponentA'
            active={activeItem === 'ComponentA'}
            onClick={this.handleItemClick}
          >
            ComponentA
          </Menu.Item>

          <Menu.Item
            as={Link} to="/hindinews"
            name='HindiNews'
            active={activeItem === 'HindiNews'}
            onClick={this.handleItemClick}
          >
            Hindi News
          </Menu.Item>
          <Menu.Item
            as={Link} to="/componentb"
            name='ComponentB'
            active={activeItem === 'ComponentB'}
            onClick={this.handleItemClick}
          >
            ComponentB
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/ajit"
            name='Ajit Template'
            active={activeItem === 'Ajit Template'}
            onClick={this.handleItemClick}
          >
            Ajit Template
          </Menu.Item>
          <Menu.Item
            as={Link} to="/vanityfair"
            name='Vanity Fair'
            active={activeItem === 'Vanity Fair'}
            onClick={this.handleItemClick}
          >
            Vanity Fair
          </Menu.Item>
          <Menu.Item
            as={Link} to="/images"
            name='Images'
            active={activeItem === 'Images'}
            onClick={this.handleItemClick}
          >
            Images
          </Menu.Item>
          <Menu.Item
              as={Link} to="/stocks"
              name='Stocks'
              active={activeItem === 'Stocks'}
              onClick={this.handleItemClick}
          >
            Stocks
          </Menu.Item>
        </Menu>
        <div className={"container"}>
          <Routes>
            <Route path="/" element={<App/>}/>
            <Route path="/the-hindu/IMA-withdraws-planned-protest-after-assurance-from-Amit-Shah" element={<ComponentA/>}/>
            <Route path="/hindinews" element={<HindiNews/>}/>
            <Route path="/componentB" element={<ComponentB/>}/>
            <Route path="/ajit" element={<AjitTemplate/>}/>
            <Route path="/newsboard" element={<NewsBoard/>}/>
            <Route path="/nerdstats" element={<NerdStats/>}/>
            <Route path="/settings" element={<NewsBoard/>}/>
            <Route path="/vanityfair" element={<VanityFair/>}/>
            <Route path="/images" element={<Slideshow/>}/>
            <Route path="/stocks" element={<Stocks/>}/>
          </Routes>
        </div>
      </Router>
    );
  }
}
