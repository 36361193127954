import React from 'react';
import "../my.scss";
import { VictoryBar,VictoryChart,VictoryScatter,VictoryLabel,VictoryAxis,VictoryLine,VictoryPolarAxis } from 'victory';

const data = [
    {quarter: 1, earnings: 13000},
    {quarter: 2, earnings: 16500},
    {quarter: 3, earnings: 14250},
    {quarter: 4, earnings: 19000}
];

// const dataSetIwant = [
//     {
//         politics:1,
//         entertainment:1,
//         sports:1,
//         business:1,
//         world:1,
//     },
//     {
//         scale:'india',
//         state:'punjab'

//     },
//     {
//         topic:[]
//     }

// ];

class CustomTheme extends React.Component {
    render() {
        const styles = this.getStyles();
        const dataSetOne = this.getDataSetOne();
        const dataSetTwo = this.getDataSetTwo();
        const tickValues = this.getTickValues();



        return (
            <div>
                <svg style={styles.parent} viewBox="0 0 450 350">

                    {/* Create stylistic elements */}
                    <rect x="0" y="0" width="10" height="30" fill="#f01616"/>
                    <rect x="420" y="10" width="20" height="20" fill="#458ca8"/>

                    {/* Define labels */}
                    <VictoryLabel x={25} y={24} style={styles.title} text="An outlook"/>
                    <VictoryLabel x={430} y={20} style={styles.labelNumber} text="1"/>
                    <VictoryLabel x={25} y={55} style={styles.labelOne} text={"Economy \n % change on a year earlier"}/>
                    <VictoryLabel x={425} y={55} style={styles.labelTwo} text={"Dinosaur exports\n $bn"}/>

                    <g transform={"translate(0, 40)"}>
                        {/* Add shared independent axis */}
                        <VictoryAxis
                            scale="time"
                            standalone={false}
                            style={styles.axisYears}
                            tickValues={tickValues}
                            tickFormat={
                                (x) => {
                                    if (x.getFullYear() === 2000) {
                                        return x.getFullYear();
                                    }
                                    if (x.getFullYear() % 5 === 0) {
                                        return x.getFullYear().toString().slice(2);
                                    }
                                }
                            }
                        />

                        {/*
              Add the dependent axis for the first data set.
              Note that all components plotted against this axis will have the same y domain
            */}
                        <VictoryAxis dependentAxis
                                     domain={[-10, 15]}
                                     offsetX={50}
                                     orientation="left"
                                     standalone={false}
                                     style={styles.axisOne}
                        />

                        {/* Red annotation line */}
                        <VictoryLine
                            data={[
                                {x: new Date(1999, 1, 1), y: 0},
                                {x: new Date(2014, 6, 1), y: 0}
                            ]}
                            domain={{
                                x: [new Date(1999, 1, 1), new Date(2016, 1, 1)],
                                y: [-10, 15]
                            }}
                            scale={{x: "time", y: "linear"}}
                            standalone={false}
                            style={styles.lineThree}
                        />

                        {/* dataset one */}
                        <VictoryLine
                            data={dataSetOne}
                            domain={{
                                x: [new Date(1999, 1, 1), new Date(2016, 1, 1)],
                                y: [-10, 15]
                            }}
                            interpolation="monotoneX"
                            scale={{x: "time", y: "linear"}}
                            standalone={false}
                            style={styles.lineOne}
                        />

                        {/*
              Add the dependent axis for the second data set.
              Note that all components plotted against this axis will have the same y domain
            */}
                        <VictoryAxis dependentAxis
                                     domain={[0, 50]}
                                     orientation="right"
                                     standalone={false}
                                     style={styles.axisTwo}
                        />

                        {/* dataset two */}
                        <VictoryLine
                            data={dataSetTwo}
                            domain={{
                                x: [new Date(1999, 1, 1), new Date(2016, 1, 1)],
                                y: [0, 50]
                            }}
                            interpolation="monotoneX"
                            scale={{x: "time", y: "linear"}}
                            standalone={false}
                            style={styles.lineTwo}
                        />
                    </g>
                </svg>
            </div>
        );
    }

    getDataSetOne() {
        return [
            {x: new Date(2000, 1, 1), y: 12},
            {x: new Date(2000, 6, 1), y: 10},
            {x: new Date(2000, 12, 1), y: 11},
            {x: new Date(2001, 1, 1), y: 5},
            {x: new Date(2002, 1, 1), y: 4},
            {x: new Date(2003, 1, 1), y: 6},
            {x: new Date(2004, 1, 1), y: 5},
            {x: new Date(2005, 1, 1), y: 7},
            {x: new Date(2006, 1, 1), y: 8},
            {x: new Date(2007, 1, 1), y: 9},
            {x: new Date(2008, 1, 1), y: -8.5},
            {x: new Date(2009, 1, 1), y: -9},
            {x: new Date(2010, 1, 1), y: 5},
            {x: new Date(2013, 1, 1), y: 1},
            {x: new Date(2014, 1, 1), y: 2},
            {x: new Date(2015, 1, 1), y: -5}
        ];
    }

    getDataSetTwo() {
        return [
            {x: new Date(2000, 1, 1), y: 5},
            {x: new Date(2003, 1, 1), y: 6},
            {x: new Date(2004, 1, 1), y: 4},
            {x: new Date(2005, 1, 1), y: 10},
            {x: new Date(2006, 1, 1), y: 12},
            {x: new Date(2007, 2, 1), y: 48},
            {x: new Date(2008, 1, 1), y: 19},
            {x: new Date(2009, 1, 1), y: 31},
            {x: new Date(2011, 1, 1), y: 49},
            {x: new Date(2014, 1, 1), y: 40},
            {x: new Date(2015, 1, 1), y: 21}
        ];
    }

    getTickValues() {
        return [
            new Date(1999, 1, 1),
            new Date(2000, 1, 1),
            new Date(2001, 1, 1),
            new Date(2002, 1, 1),
            new Date(2003, 1, 1),
            new Date(2004, 1, 1),
            new Date(2005, 1, 1),
            new Date(2006, 1, 1),
            new Date(2007, 1, 1),
            new Date(2008, 1, 1),
            new Date(2009, 1, 1),
            new Date(2010, 1, 1),
            new Date(2011, 1, 1),
            new Date(2012, 1, 1),
            new Date(2013, 1, 1),
            new Date(2014, 1, 1),
            new Date(2015, 1, 1),
            new Date(2016, 1, 1)
        ];
    }

    getStyles() {
        const BLUE_COLOR = "#00a3de";
        const RED_COLOR = "#7c270b";

        return {
            parent: {
                background: "#ccdee8",
                boxSizing: "border-box",
                display: "inline",
                padding: 0,
                fontFamily: "'Fira Sans', sans-serif",
                maxWidth: "50%",
                height: "auto"
            },
            title: {
                textAnchor: "start",
                verticalAnchor: "end",
                fill: "#000000",
                fontFamily: "inherit",
                fontSize: "18px",
                fontWeight: "bold"
            },
            labelNumber: {
                textAnchor: "middle",
                fill: "#eee",
                fontFamily: "inherit",
                fontSize: "14px"
            },

            // INDEPENDENT AXIS
            axisYears: {
                axis: { stroke: "black", strokeWidth: 1},
                ticks: {
                    size: ({ tick }) => {
                        const tickSize =
                            tick.getFullYear() % 5 === 0 ? 10 : 5;
                        return tickSize;
                    },
                    stroke: "black",
                    strokeWidth: 1
                },
                tickLabels: {
                    fill: "black",
                    fontFamily: "inherit",
                    fontSize: 16
                }
            },

            // DATA SET ONE
            axisOne: {
                grid: {
                    stroke: ({ tick }) =>
                        tick === -10 ? "transparent" : "#eee",
                    strokeWidth: 2
                },
                axis: { stroke: BLUE_COLOR, strokeWidth: 0 },
                ticks: { strokeWidth: 0 },
                tickLabels: {
                    fill: BLUE_COLOR,
                    fontFamily: "inherit",
                    fontSize: 16
                }
            },
            labelOne: {
                fill: BLUE_COLOR,
                fontFamily: "inherit",
                fontSize: 12,
                fontStyle: "italic"
            },
            lineOne: {
                data: { stroke: BLUE_COLOR, strokeWidth: 4.5 }
            },
            axisOneCustomLabel: {
                fill: BLUE_COLOR,
                fontFamily: "inherit",
                fontWeight: 300,
                fontSize: 21
            },

            // DATA SET TWO
            axisTwo: {
                axis: { stroke: RED_COLOR, strokeWidth: 0 },
                tickLabels: {
                    fill: RED_COLOR,
                    fontFamily: "inherit",
                    fontSize: 16
                }
            },
            labelTwo: {
                textAnchor: "end",
                fill: RED_COLOR,
                fontFamily: "inherit",
                fontSize: 12,
                fontStyle: "italic"
            },
            lineTwo: {
                data: { stroke: RED_COLOR, strokeWidth: 4.5 }
            },

            // HORIZONTAL LINE
            lineThree: {
                data: { stroke: "#e95f46", strokeWidth: 2 }
            }
        };
    }
}

class CustomChart extends React.Component {
    render() {
        const styles = this.getStyles();
        const dataSetOne = this.getDataSetOne();
        const dataSetTwo = this.getDataSetTwo();
        const tickValues = this.getTickValues();

        return (
            <div>
                <svg style={styles.parent} viewBox="0 0 450 350">

                    {/* Create stylistic elements */}
                    <rect x="0" y="0" width="10" height="30" fill="#f01616"/>
                    <rect x="420" y="10" width="20" height="20" fill="#458ca8"/>

                    {/* Define labels */}
                    <VictoryLabel x={25} y={24} style={styles.title} text="An outlook"/>
                    <VictoryLabel x={430} y={20} style={styles.labelNumber} text="1"/>
                    <VictoryLabel x={25} y={55} style={styles.labelOne} text={"Economy \n % change on a year earlier"}/>
                    <VictoryLabel x={425} y={55} style={styles.labelTwo} text={"Dinosaur exports\n $bn"}/>

                    <g transform={"translate(0, 40)"}>
                        {/* Add shared independent axis */}
                        <VictoryAxis
                            scale="time"
                            standalone={false}
                            style={styles.axisYears}
                            tickValues={tickValues}
                            tickFormat={
                                (x) => {
                                    if (x.getFullYear() === 2000) {
                                        return x.getFullYear();
                                    }
                                    if (x.getFullYear() % 5 === 0) {
                                        return x.getFullYear().toString().slice(2);
                                    }
                                }
                            }
                        />

                        {/*
              Add the dependent axis for the first data set.
              Note that all components plotted against this axis will have the same y domain
            */}
                        <VictoryAxis dependentAxis
                                     domain={[-10, 15]}
                                     offsetX={50}
                                     orientation="left"
                                     standalone={false}
                                     style={styles.axisOne}
                        />

                        {/* Red annotation line */}
                        <VictoryLine
                            data={[
                                {x: new Date(1999, 1, 1), y: 0},
                                {x: new Date(2014, 6, 1), y: 0}
                            ]}
                            domain={{
                                x: [new Date(1999, 1, 1), new Date(2016, 1, 1)],
                                y: [-10, 15]
                            }}
                            scale={{x: "time", y: "linear"}}
                            standalone={false}
                            style={styles.lineThree}
                        />

                        {/* dataset one */}
                        <VictoryLine
                            data={dataSetOne}
                            domain={{
                                x: [new Date(1999, 1, 1), new Date(2016, 1, 1)],
                                y: [-10, 15]
                            }}
                            interpolation="monotoneX"
                            scale={{x: "time", y: "linear"}}
                            standalone={false}
                            style={styles.lineOne}
                        />

                        {/*
              Add the dependent axis for the second data set.
              Note that all components plotted against this axis will have the same y domain
            */}
                        <VictoryAxis dependentAxis
                                     domain={[0, 50]}
                                     orientation="right"
                                     standalone={false}
                                     style={styles.axisTwo}
                        />

                        {/* dataset two */}
                        <VictoryLine
                            data={dataSetTwo}
                            domain={{
                                x: [new Date(1999, 1, 1), new Date(2016, 1, 1)],
                                y: [0, 50]
                            }}
                            interpolation="monotoneX"
                            scale={{x: "time", y: "linear"}}
                            standalone={false}
                            style={styles.lineTwo}
                        />
                    </g>
                </svg>
            </div>
        );
    }

    getDataSetOne() {
        return [
            {x: new Date(2000, 1, 1), y: 12},
            {x: new Date(2000, 6, 1), y: 10},
            {x: new Date(2000, 12, 1), y: 11},
            {x: new Date(2001, 1, 1), y: 5},
            {x: new Date(2002, 1, 1), y: 4},
            {x: new Date(2003, 1, 1), y: 6},
            {x: new Date(2004, 1, 1), y: 5},
            {x: new Date(2005, 1, 1), y: 7},
            {x: new Date(2006, 1, 1), y: 8},
            {x: new Date(2007, 1, 1), y: 9},
            {x: new Date(2008, 1, 1), y: -8.5},
            {x: new Date(2009, 1, 1), y: -9},
            {x: new Date(2010, 1, 1), y: 5},
            {x: new Date(2013, 1, 1), y: 1},
            {x: new Date(2014, 1, 1), y: 2},
            {x: new Date(2015, 1, 1), y: -5}
        ];
    }

    getDataSetTwo() {
        return [
            {x: new Date(2000, 1, 1), y: 5},
            {x: new Date(2003, 1, 1), y: 6},
            {x: new Date(2004, 1, 1), y: 4},
            {x: new Date(2005, 1, 1), y: 10},
            {x: new Date(2006, 1, 1), y: 12},
            {x: new Date(2007, 2, 1), y: 48},
            {x: new Date(2008, 1, 1), y: 19},
            {x: new Date(2009, 1, 1), y: 31},
            {x: new Date(2011, 1, 1), y: 49},
            {x: new Date(2014, 1, 1), y: 40},
            {x: new Date(2015, 1, 1), y: 21}
        ];
    }

    getTickValues() {
        return [
            new Date(1999, 1, 1),
            new Date(2000, 1, 1),
            new Date(2001, 1, 1),
            new Date(2002, 1, 1),
            new Date(2003, 1, 1),
            new Date(2004, 1, 1),
            new Date(2005, 1, 1),
            new Date(2006, 1, 1),
            new Date(2007, 1, 1),
            new Date(2008, 1, 1),
            new Date(2009, 1, 1),
            new Date(2010, 1, 1),
            new Date(2011, 1, 1),
            new Date(2012, 1, 1),
            new Date(2013, 1, 1),
            new Date(2014, 1, 1),
            new Date(2015, 1, 1),
            new Date(2016, 1, 1)
        ];
    }

    getStyles() {
        const BLUE_COLOR = "#00a3de";
        const RED_COLOR = "#7c270b";

        return {
            parent: {
                background: "#fff",
                boxSizing: "border-box",
                display: "inline",
                padding: 0,
                height: "auto"
            },
            title: {
                textAnchor: "start",
                verticalAnchor: "end",
                fill: "#000000",
                fontFamily: "inherit",
                fontSize: "18px",
                fontWeight: "bold"
            },
            labelNumber: {
                textAnchor: "middle",
                fill: "#eee",
                fontFamily: "inherit",
                fontSize: "14px"
            },

            // INDEPENDENT AXIS
            axisYears: {
                axis: { stroke: "black", strokeWidth: 1},
                ticks: {
                    size: ({ tick }) => {
                        const tickSize =
                            tick.getFullYear() % 5 === 0 ? 10 : 5;
                        return tickSize;
                    },
                    stroke: "black",
                    strokeWidth: 1
                },
                tickLabels: {
                    fill: "black",
                    fontFamily: "inherit",
                    fontSize: 16
                }
            },

            // DATA SET ONE
            axisOne: {
                grid: {
                    stroke: ({ tick }) => tick === -10 ? "transparent" : "#111",
                    strokeWidth: 0.7
                },
                axis: { stroke: BLUE_COLOR, strokeWidth: 1 },
                ticks: { strokeWidth: 0 },
                tickLabels: {
                    fill: BLUE_COLOR,
                    fontFamily: "inherit",
                    fontSize: 16
                }
            },
            labelOne: {
                fill: BLUE_COLOR,
                fontFamily: "inherit",
                fontSize: 12,
                fontStyle: "italic"
            },
            lineOne: {
                data: { stroke: BLUE_COLOR, strokeWidth: 4.5 }
            },
            axisOneCustomLabel: {
                fill: BLUE_COLOR,
                fontFamily: "inherit",
                fontWeight: 300,
                fontSize: 21
            },

            // DATA SET TWO
            axisTwo: {
                axis: { stroke: RED_COLOR, strokeWidth: 0 },
                tickLabels: {
                    fill: RED_COLOR,
                    fontFamily: "inherit",
                    fontSize: 16
                }
            },
            labelTwo: {
                textAnchor: "end",
                fill: RED_COLOR,
                fontFamily: "inherit",
                fontSize: 12,
                fontStyle: "italic"
            },
            lineTwo: {
                data: { stroke: RED_COLOR, strokeWidth: 4.5 }
            },

            // HORIZONTAL LINE
            lineThree: {
                data: { stroke: "#e95f46", strokeWidth: 2 }
            }
        };
    }
}
const colors = [ "#428517", "#77D200", "#D6D305", "#EC8E19", "#C92B05"];

export default class NerdStats extends React.Component {

    render() {
        return <div className={"nerdcontainer"} style={{minHeight: "100vh"}}>
            <div className={"nerdstats"}>

              <VictoryChart polar>
                {
                  ["Entertainment", "Sports", "Business", "Lifestyle", "Tech"].map((d, i) => {
                    return (
                      <VictoryPolarAxis dependentAxis
                                        key={i}
                                        label={d}
                                        labelPlacement="perpendicular"
                                        style={{tickLabels: {fill: "none"}}}
                                        axisValue={d}
                      />
                    );
                  })
                }
                <VictoryBar
                  style={{data: {fill: "rgba(33,33,33,0.6)", width: 25}}}
                  data={[
                    {x: "Entertainment", y: 10},
                    {x: "Sports", y: 25},
                    {x: "Business", y: 40},
                    {x: "Lifestyle", y: 50},
                    {x: "Tech", y: 50}
                  ]}
                />
              </VictoryChart>
              <VictoryChart polar>
                {
                  ["Entertainment", "Sports", "Business", "Lifestyle", "Tech"].map((d, i) => {
                    return (
                      <VictoryPolarAxis dependentAxis
                                        key={i}
                                        label={d}
                                        labelPlacement="perpendicular"
                                        style={{tickLabels: {fill: "none"}}}
                                        axisValue={d}
                      />
                    );
                  })
                }
                <VictoryBar
                  style={{data: {fill: "rgba(33,33,33,0.6)", width: 25}}}
                  data={[
                    {x: "Entertainment", y: 10},
                    {x: "Sports", y: 25},
                    {x: "Business", y: 40},
                    {x: "Lifestyle", y: 50},
                    {x: "Tech", y: 50}
                  ]}
                />
              </VictoryChart>

              <VictoryChart animate={{duration: 2000, easing: "bounce"}}>
                <VictoryBar
                  data={data}
                  x="quarter"
                  y="earnings"
                />
              </VictoryChart>

            </div>



            <div className={"nerdstats"}>


                <VictoryChart animate={{ duration: 2000, easing: "bounce" }} domain={{ x: [1, 12], y: [0, 7] }}>
                    { [5, 4, 3, 2, 1].map((val, i) => {
                        return (
                            <VictoryLine
                                key={i}
                                samples={100}
                                style={{ data: { stroke: colors[i] } }}
                                y={(d) => val * (1 - Math.cos(d.x))}
                            />
                        );
                    })}
                    <VictoryScatter name="series-1" style={{ data: { fill: "#c43a31" } }} bubbleProperty="amount" size={7} maxBubbleSize={25} minBubbleSize={5} data={[
                                        {
                        x: 3, y: 2,  amount: 30,
                        label: "Politics"
                    },
                    {
                        x: 2, y: 3, amount: 40,
                        label: "India"
                    },
                    {
                        x: 3, y: 5, amount: 25,
                        label: "Alpha"
                    },
                    {
                        x: 4, y: 4, amount: 10,
                        label: "Business"
                    },
                    {
                        x: 5, y: 7, amount: 45,
                        label: "Entertainment"
                    }
                ]}/>
                        <VictoryScatter  style={{ data: { fill: "rgba(37, 37, 37,0.6)" } }} bubbleProperty="amount" size={7} maxBubbleSize={20} minBubbleSize={2} data={[
                        {
                            x: 3, y: 2,  amount: 30,
                            label: "politics"
                        },
                        {
                            x: 2, y: 3, amount: 40,
                            label: "india"
                        },
                        {
                            x: 3, y: 5, amount: 25,
                            label: "Alpha"
                        },
                        {
                            x: 4, y: 4, amount: 10,
                            label: "business"
                        },
                        {
                            x: 5, y: 7, amount: 45,
                            label: "entertainment"
                        }
                    ]}/>
                </VictoryChart>

            </div>
            <div className={"nerdstats"}>
            <CustomChart />
            </div>
                <div className={"nerdstats"}>
            <CustomTheme />
                </div>
        </div>
    }
}
