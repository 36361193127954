import React from 'react';
import "../my.scss";
import { FaHeart,FaRegBookmark, FaBookmark } from 'react-icons/fa';
export default class ComponentA extends React.Component {


    wordCount=(doc)=>{
        return doc.length;
    }
    sentenceCount = doc=>{
        let sentences = doc.split(". ");
        return sentences;
    }



    constructor() {
        super();
        this.state={
            data:` The Indian Medical Association (IMA) on Wednesday withdrew its protest planned in the wake of a spate of attacks on doctors and healthcare professionals after an assurance by the Centre that all necessary steps, including relevant legislation, will be introduced for their safety and dignity.

            There have been several instances of medical teams coming under attack as they went about collecting samples of suspected COVID-19 patients from many parts of the country. In Tamil Nadu, a doctor who died of COVID-19 infection was denied burial and his colleagues were attacked.

            Union Home Minister Amit Shah and Health Minister Harshvardhan convened a video conference with representatives of the IMA and other doctors on Wednesday to address the issue.

             “The way our doctors are performing their duties in these testing times is exceptional. I urge every Indian to cooperate with doctors in this fight against COVID-19. Safety and dignity of our doctors at their work place is non-negotiable. It is our collective responsibility to ensure a conducive atmosphere for them at all times. I have assured doctors that Modi govt is committed to their cause and appealed to reconsider their proposed protest,” Mr. Shah tweeted after the meeting.

            The IMA released a statement later that said, “Considering the immediate high level response of government and solid assurance given by the Home Minister himself, it is decided that the white alert protest of April 22 and Black Day (protest) of May 23 stands withdrawn to maintain the unity and integrity of the country.”`
        };

    }
    render() {
        return <div className={"component"} style={{minHeight : "100vh"}}>
                <div className={"thehindu"}>
                    <FaHeart />
                    <FaRegBookmark />
                    <FaBookmark />90%
                    <img src="https://www.thehindu.com/static/theme/default/base/img/logo.png"  alt={""}/>
                </div>
                <div className={"contentContainer"}>

                    <h2>
                        IMA withdraws planned protest after assurance from Amit Shah
                    </h2>
                    <i>NEW DELHI, APRIL 22, 2020 11:30 IST
                    </i>

                    <p>
                        Word Count:  {this.wordCount(this.state.data)}<br />
                        Sentence Count:


                    </p>
                    <p>
                        <img className={"titleImage"} src="https://www.thehindu.com/news/national/5mqwd5/article31402887.ece/ALTERNATES/FREE_960/AMITSHAH"  alt={""}/>
                    </p>

                    <div className={"articleContent"}>

                      <div className={"articleSpacer"}></div>
                        {this.state.data}
                    </div>

                </div>

        </div>
    }
}
