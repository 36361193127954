import React from 'react';
import "../my.scss";
import { Button,Grid, Checkbox, Form, Icon } from 'semantic-ui-react'
import ReCAPTCHA from "react-google-recaptcha";
import Recaptcha from 'react-recaptcha';

function onChange(value) {
  console.log("Captcha value:", value);
}

export default class Login extends React.Component {
  render() {
   return <React.Fragment>
     <Grid centered><Grid.Column width={6}>

       <Form>
         <Form.Field>
           <label>Email</label>
           <input placeholder='Email' />
         </Form.Field>
         <Form.Field>
           <label>Password</label>
           <input type={"password"} placeholder='Password' />
         </Form.Field>
         <Form.Field>
           <Checkbox label='Remember Me' />
         </Form.Field>
         <Button type='submit'>Submit</Button>
       </Form>
       <Icon link name='facebook' />
     </Grid.Column></Grid>
     Captcha

     <Recaptcha
       sitekey="6LeAd_sUAAAAANxyxXPLPx5DowFJHHkGkC2I67ws"
     />

    </React.Fragment>
  }
}
